import { Link } from "react-router-dom";
import { Nav, Sidebar, Sidenav } from "rsuite";
import menuBars from "./../../../SVGIcons/svgs/menu_bars.svg";
import IconDashboard from "./../../../SVGIcons/SVG/components/IconDashboard";
import IconRequestLegalSupport from "./../../../SVGIcons/SVG/components/IconRequestLegalSupport";
import IconClientResources from "./../../../SVGIcons/SVG/components/IconClientResources";
import IconWorkflowManagment from "./../../../SVGIcons/SVG/components/IconWorkflowManagement";
import IconQuestion from "./../../../SVGIcons/SVG/components/IconQuestion";
import IconFolder from "./../../../SVGIcons/SVG/components/IconFolder";
import IconWisdom from "./../../../SVGIcons/SVG/components/IconWisdom";
import IconRightArrow from "./../../../SVGIcons/SVG/components/IconRightArrow";
import IconManagementCenter from './../../../SVGIcons/SVG/components/IconManagementCenter'
import IconContractData from "../../../SVGIcons/SVG/components/IconContractData";
import IconIntegration from '../../../SVGIcons/SVG/components/IconIntegration';
import IconSignedContract from "../../../SVGIcons/SVG/components/IconSignedContract";
import IconSignedContracts from "../../../SVGIcons/SVG/components/IconSignedContracts";
import IconContractStorage from "../../../SVGIcons/SVG/components/IconContractStorage";
import IconCustomReports from "../../../SVGIcons/SVG/components/IconCustomReports";
import IconReporting from "../../../SVGIcons/SVG/components/IconReporting";
import IconTemplate from "../../../SVGIcons/SVG/components/IconTemplate";
import IconConsolidatedInstance from "../../../SVGIcons/SVG/components/IconConsolidatedInstance";
import _ from "lodash";
import React from "react";
import { hasPermissionsToViewPage } from "../../../../../services/authService";
import paths from "../../../../../config/paths";
import IconManageCounterParty from "../../../SVGIcons/SVG/components/IconManageCounterParty";
import IconDeletedMatter from "../../../SVGIcons/SVG/components/IconDeletedMatter";
import IconWorkflowAdmin from "../../../SVGIcons/SVG/components/IconWorkflowAdmin";
import IconNewWorkflow from "../../../SVGIcons/SVG/components/IconNewWorkflow";

const LinkWrapper = React.forwardRef((parentProps, ref) => {
    const { href, as, history, to, children, ...rest } = parentProps;

    return (

        <Link ref={ref} as={as} to={to} {...rest}>
            {children}
        </Link>
    );
});

const NavLink = (props) => <Nav.Item componentClass={LinkWrapper} {...props} />;

const ClientSidebar = (props) => {
    const { expand, location, history, handleToggle } = props;
    return (
        <Sidebar
            style={{ display: "flex", flexDirection: "column" }}
            width={expand ? 240 : 56}
            collapsible
        >
            <Sidenav expanded={expand} defaultOpenKeys={["3"]} appearance="subtle">
                <Sidenav.Header>
                    <div className="sidenav-header">
                        <Link to={"/"}> {!expand || (<span>  {" "}  <img src={!_.isEmpty(props.logo) ? props.logo : "/logo.png"} alt="LightHouseHub" />  </span>)}</Link>
                        <img
                            src={menuBars}
                            style={{ verticalAlign: 1, float: "right", paddingTop: 10 }}
                            onClick={handleToggle}
                            alt="menu_bars"
                        />
                    </div>
                </Sidenav.Header>
                <Sidenav.Body className="sidenav-body" id="overflow-sidebar">
                    <Nav>
                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, "DASHBOARD") && (
                            <NavLink eventKey="1" to="/" history={history} active={location.pathname === "/"} icon={<IconDashboard className="rs-icon" />} >  Dashboard  </NavLink>
                        )}

                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, "NEW_MATTER") && (
                            <NavLink eventKey="4" to="/new-matter" history={history} active={location.pathname === "/new-matter"} icon={<IconRequestLegalSupport className="rs-icon" />}  > New Matter  </NavLink>
                        )}

                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, "WORKFLOW_OLD") && (
                            <NavLink eventKey="3" to="/workflow-old" history={history} active={location.pathname === '/workflow-old'} icon={<IconManagementCenter className="rs-icon" />}>
                                {/* Management Center */}
                                Workflow (Old)
                            </NavLink>
                        )}

                         {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, "WORKFLOW") && (
                            <NavLink eventKey="3" to="/workflow" history={history} active={location.pathname === '/workflow'} icon={<IconNewWorkflow className="rs-icon" />}>
                                Workflow
                            </NavLink>
                        )}
                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, "SIGNED_CONTRACTS") && (
                            <NavLink eventKey="6" to="/signed-contracts" history={history} active={location.pathname === "/signed-contracts"} icon={<IconSignedContracts className="rs-icon" />}  > Signed Contracts </NavLink>
                        )}
                        {!_.isEmpty(props.user) && props.user.isLHLawyer && new RegExp(['za', 'beta', 'staging'].join("|")).test(paths.frontUrl) && (
                            <NavLink eventKey="16" to="/capacity-planning" history={history} active={location.pathname === "/capacity-planning"} icon={<IconConsolidatedInstance className="rs-icon" style={{ width: 20, marginLeft: 3 }} />}  > Capacity Planning </NavLink>
                        )}
                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, "WORKFLOW_MANAGEMENT") && props.user.customLegalRequestForm && (
                            <NavLink eventKey="8" to="/wrike" history={history} active={location.pathname === "/wrike"} icon={<IconRightArrow className="rs-icon" style={{ width: 20, marginLeft: 3 }} />}   > Wrike  </NavLink>
                        )}

                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, "CONTRACT_STORAGE") && props.user.customLegalRequestForm && (
                            <NavLink eventKey="19" to="/contract-storage" history={history} active={location.pathname === "/contract-storage"} icon={<IconContractStorage className="rs-icon" style={{ width: 20, marginLeft: 3 }} />}>  Contract Storage  </NavLink>
                        )}



                        {/* {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, 'CONTRACT_MANAGEMENT') && <NavLink eventKey="6" to="/contract-management" history={history} active={location.pathname === '/contract-management'} icon={<IconContractManagement className="rs-icon" />}>
                            Contract Management
                        </NavLink>} */}

                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, "REPORTING") && (
                            <NavLink eventKey="11" to="/reporting" history={history} active={location.pathname === "/reporting"} icon={<IconReporting className="rs-icon" style={{ width: 20, marginLeft: 3 }} />} >  Reporting  </NavLink>
                        )}

                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, "CONTRACT_DATA") && (
                            <NavLink eventKey="6" to="/contract-data" history={history} active={location.pathname === "/contract-data"} icon={<IconContractData className="rs-icon" />}  > Contract Data </NavLink>
                        )}

                        {!_.isEmpty(props.user) && ((props.user.isCustomReportActive && !props.isSubUser) || (props.isSubUser && hasPermissionsToViewPage(props.user, "CUSTOM_REPORTS")))  && (
                            <NavLink eventKey="9" to="/custom-reports" history={history} active={location.pathname === "/custom-reports"} icon={<IconCustomReports className="rs-icon" style={{ width: 20, marginLeft: 3 }} />} >  Custom Reports  </NavLink>
                        )}

                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, "TIME_LOG") && (
                            <NavLink eventKey="20" to="/timelog" history={history} active={location.pathname === "/timelog"} icon={<IconContractData className="rs-icon" />}  > Time Log </NavLink>
                        )}

                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, "MANAGE_TEMPLATE") && (
                            <NavLink eventKey="6" to="/templates" history={history} active={location.pathname === "/templates"} icon={<IconTemplate className="rs-icon" />}  > {/* Workflow Management */} Templates </NavLink>
                        )}

                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, "SIGNED_CONTRACT") && (
                            <NavLink eventKey="6" to="/applications" history={history} active={location.pathname === "/applications"} icon={<IconSignedContract className="rs-icon" />}  > Applications </NavLink>
                        )}

                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, "RESOURCES") && (
                            <NavLink eventKey="5" to="/client-resources" history={history} active={location.pathname === "/client-resources"} icon={<IconClientResources className="rs-icon" />}  > Resources </NavLink>
                        )}

                        {/* {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, "RESOURCES") && (
                            <NavLink eventKey="5" to="/standard-contract-templates" history={history} active={location.pathname === "/standard-contract-templates"} icon={<IconClientResources className="rs-icon" />}  > Standard Contract Templates </NavLink>
                        )} */}

                        {!_.isEmpty(props.user) && (
                            <NavLink eventKey="10" to="/mylearnedfriend" history={history} active={location.pathname === "/mylearnedfriend"} icon={<IconWisdom className="rs-icon" />}   > My Learned Friend  </NavLink>
                        )}

                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user) && (
                            <NavLink eventKey="7" to="/how-to-use-lighthub" history={history} active={location.pathname === "/how-to-use-lighthub"} icon={<IconQuestion className="rs-icon" />}  > How to use LightHub  </NavLink>
                        )}

                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, "MATTER_ADMIN") && (
                            <NavLink eventKey="4" to="/matter-admin" history={history} active={location.pathname === "/matter-admin"} icon={<IconRequestLegalSupport className="rs-icon" />}  >Matter Admin  </NavLink>
                        )}

                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, "WORKFLOW_ADMIN") && (
                            <NavLink eventKey="4" to="/workflow-admin" history={history} active={location.pathname === "/workflow-admin"} icon={<IconWorkflowAdmin className="rs-icon" />}  >Workflow Admin  </NavLink>
                        )}

                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, "MATTER_ADMIN") && (
                            <NavLink eventKey="4" to="/reporting-admin" history={history} active={location.pathname === "/reporting-admin"} icon={<IconRequestLegalSupport className="rs-icon" />}  >Reporting Admin  </NavLink>
                        )}

                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, "ADMIN") && (
                            <NavLink eventKey="8" to="/manage-counterparty" history={history} active={location.pathname === "/manage-counterparty"} icon={<IconManageCounterParty className="rs-icon"  />}> Manage Counterparties  </NavLink>
                        )}
                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, "ADMIN") && (
                            <NavLink eventKey="8" to="/manage-deleted-matters" history={history} active={location.pathname === "/manage-deleted-matters"} icon={<IconDeletedMatter className="rs-icon"  />}   > Manage Deleted Matters  </NavLink>
                        )}

                        {!_.isEmpty(props.user) && hasPermissionsToViewPage(props.user, "INTEGRATIONS") && (
                            <NavLink eventKey="6" to="/integrations" history={history} active={location.pathname === "/integrations"} icon={<IconIntegration className="rs-icon" />}  > {/* Workflow Management */} Integrations </NavLink>
                        )}

                    </Nav>
                </Sidenav.Body>
            </Sidenav>
        </Sidebar>
    );
};

export default ClientSidebar;
