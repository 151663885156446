import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Route, Redirect, } from 'react-router-dom';

import { isAuthenticated, loginUrl, isAdmin, otpVerificationPageUrl } from '../../../services/authService';
import Toast from '../../../services/toastService';
import userService from '../../../services/userService';

export const Private = (props) => {
    const { component: Component, isAdminRoute = false, loader: Loader, pageKey, ...rest } = props;

    const [isUserAuthenticated, setIsAuthenticated] = useState(null);
    const [isUserAdmin, setIsUserAdmin] = useState(null);

    const [otpVerification, setOtpVerification] = useState(null);

    const [user, setUser] = useState(null);

    useEffect(() => {
        isAuthenticated().then(value => {
            setIsAuthenticated(value);
        });

        isAdmin().then(value => {
            setIsUserAdmin(value);
        });

        userService.getOTPVerification().then(value => {
            setOtpVerification(value);
        });

        userService.getUser().then(value => {
            setUser(value);
        });

    }, []);

    const render = isUserAuthenticated === null || isUserAdmin === null ? () => Loader ? <Loader /> : <div>Loading</div> : props => {
        if (!isUserAuthenticated) {
            return <Redirect to={loginUrl} />;
        }

        if (isUserAuthenticated && otpVerification === null && window.location.pathname !== otpVerificationPageUrl) {
            return Loader ? <Loader /> : <div>Loading</div>;
        } else if (isUserAuthenticated && otpVerification !== null && window.location.pathname !== otpVerificationPageUrl) {
            if (otpVerification.isOTPVerificationRequired && !otpVerification.isVerified) {
                Toast.error('Please verify OTP');
                return <Redirect to={otpVerificationPageUrl} />;
            }
        }

        if (isUserAuthenticated && user === null) {
            return Loader ? <Loader /> : <div>Loadind</div>;
        } else if (isUserAuthenticated && user !== null && pageKey) {
            if (!user.isAdmin && !user.isGuestUser && user.parent && user.parent.length > 0 && user.permissions.indexOf(pageKey) === -1) {
                //Toast.error('You don\'t have permission to the requested page');
                // return <Redirect to={'/no-permission'} />
                // return <Redirect to={'/mylearnedfriend'} />
                return <Redirect to={'/new-matter'} />
            }

            if (user.isAdmin && user.parent && user.parent.length > 0 && (user.permissions.indexOf(pageKey) === -1 || _.findIndex(user.permissions, (key) => key.includes(pageKey)) === -1) && isAdminRoute) {
                Toast.error('You don\'t have permission to the requested page');
                return <Redirect to={'/no-permission'} />
            }
            if (user.isGuestUser && (pageKey !== 'WORKFLOW' && pageKey !== 'DASHBOARD' )) {
                Toast.error('You don\'t have permission to the requested page');
                return <Redirect to={'/no-permission'} />
            }
        }

        return isAdminRoute && !isUserAdmin ? <Redirect to={document.referrer} /> : <Component {...props} />;
    };

    return <Route {...rest} render={render} />;
}

export const Public = (props) => {
    const { component: Component, loader: Loader, restricted = false, ...rest } = props;

    const [isUserAuthenticated, setIsAuthenticated] = useState(null);
    useEffect(() => {
        isAuthenticated().then(value => {
            setIsAuthenticated(value);
        });
    }, []);

    const render = isUserAuthenticated === null ? () => Loader ? <Loader /> : <div>Loading</div> : props => {
        if (isUserAuthenticated && restricted) {
            return <Redirect to={'/'} />;
        }

        return <Component {...props} />;
    };

    return <Route {...rest} render={render} />;
}

const Auth = {
    Private,
    Public,
};

export default Auth;
